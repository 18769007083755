import { Navigate, Outlet } from "react-router-dom";
import authService from "services/authService";

const ProtectedRoute = ({ children }: { children?: JSX.Element }) => {
    const currentUser = authService.getCurrentUser();

    if (!currentUser) {
        return <Navigate to="/" replace />;
    }

    return children ? children : <Outlet />;
};

export default ProtectedRoute;
