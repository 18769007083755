import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { LinkContainer } from "react-router-bootstrap";
import { User } from "Types";
import eventBus from "common/eventBus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Restricted from "components/Restricted";
import { Link } from "react-router-dom";

const NavbarHeader = ({ currentUser }: { currentUser: User | undefined }) => {
    return (
        <Navbar expand="lg" className="header-bar" fixed="top">
            <Container>
                <Navbar.Brand>
                    <Link to="/">
                        <img
                            src="/anemos-logo.svg"
                            height="45"
                            className="d-inline-block align-top logo-img"
                            alt="Anemos"
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {/* USER */}
                        {currentUser && currentUser.role !== "superadmin" && (
                            <Restricted to="products.view.own">
                                <LinkContainer to="/products">
                                    <Nav.Link>My products</Nav.Link>
                                </LinkContainer>
                            </Restricted>
                        )}
                        {/* ADMIN */}
                        <Restricted to="productTypes.view">
                            <LinkContainer to="/product-types">
                                <Nav.Link>Types</Nav.Link>
                            </LinkContainer>
                        </Restricted>
                        <Restricted to="products.view">
                            <LinkContainer to="/products">
                                <Nav.Link>Products</Nav.Link>
                            </LinkContainer>
                        </Restricted>
                        <Restricted to="users.view">
                            <LinkContainer to="/users">
                                <Nav.Link>Users</Nav.Link>
                            </LinkContainer>
                        </Restricted>
                    </Nav>
                    <Restricted to="me">
                        <Nav>
                            <LinkContainer to="/profile">
                                <Nav.Link>
                                    <FontAwesomeIcon icon="user" />{" "}
                                    {currentUser?.username}
                                </Nav.Link>
                            </LinkContainer>
                            <LinkContainer
                                to="/"
                                onClick={() => eventBus.dispatch("logout")}
                            >
                                <Nav.Link>
                                    <FontAwesomeIcon icon="right-from-bracket" />
                                </Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Restricted>
                </Navbar.Collapse>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </Container>
        </Navbar>
    );
};

export default NavbarHeader;
