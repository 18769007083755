import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Product, ProductType } from "Types";
import { MouseEventHandler, useEffect, useState } from "react";
import { Button, Alert, Form } from "react-bootstrap";
import productService from "services/productService";
import productTypeService from "services/productTypeService";
import { useNavigate } from "react-router-dom";
import { alert } from "components/MessageDialog";

const ProductForm = ({
    data,
    back,
}: {
    data?: Product | null;
    back?: MouseEventHandler<HTMLButtonElement>;
}) => {
    const [product, setProduct] = useState<Product>();
    const [productTypes, setProductTypes] = useState<ProductType[]>();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => getProductTypes(), []);

    useEffect(() => {
        if (data) {
            setProduct(data);
        }
    }, [data]);

    const schema = Yup.object({
        uniqueId: Yup.string()
            .test(
                "len",
                "The serial number must be at least 6 characters.",
                (val: any) => val && val.toString().length >= 6
            )
            .matches(
                /^[^\s]*$/,
                "The serial number cannot contain any whitespace characters."
            )
            .required("This field is required!"),
        productTypeId: Yup.string().required("This field is required!"),
    }).required();

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm<Product>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        setValue("productTypeId", product?.productType._id);
    }, [productTypes, setValue, product]);

    useEffect(() => {
        if (product) {
            reset({
                id: product.id,
                uniqueId: product.uniqueId,
                productTypeId: product.productType._id,
            });
        }
    }, [product, reset]);

    const getProductTypes = () => {
        productTypeService.getProductTypes().then(
            (response) => {
                if (response.data) {
                    setProductTypes(response.data);
                }
            },
            (error) => {
                setMessage(error);
            }
        );
    };

    const onSubmit = (data: Product) => {
        setLoading(true);
        setMessage("");

        if (!data.id) {
            productService.createProduct(data).then(
                (response) => {
                    setLoading(false);
                    alert("Successfully created the product!");
                    navigate("/products");
                },
                (error) => {
                    setLoading(false);
                    setMessage(error);
                }
            );
        } else {
            productService.updateProduct(data.id, data).then(
                (response) => {
                    setLoading(false);
                    setMessage("Successfully saved.");
                },
                (error) => {
                    setLoading(false);
                    setMessage(error);
                }
            );
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {message && <Alert variant="info">{message}</Alert>}
            <Form.Group className="mb-3">
                <Form.Label>Serial number</Form.Label>
                <Form.Control type="hidden" {...register("id")} />
                <Form.Control
                    type="text"
                    placeholder="Serial number"
                    {...register("uniqueId")}
                />
                {errors.uniqueId && <Alert>{errors.uniqueId?.message}</Alert>}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Type</Form.Label>
                <Form.Select
                    {...register("productTypeId", {
                        onChange: (e) => {
                            setValue("productTypeId", e.target.value);
                        },
                    })}
                >
                    <option>Select a type from the list...</option>
                    {productTypes &&
                        productTypes.map((type, i) => (
                            <option key={`productType${i}`} value={type._id}>
                                {type.name + "(" + type.uniqueId + ")"}
                            </option>
                        ))}
                </Form.Select>
                {errors.productType && (
                    <Alert>{errors.productType?.message}</Alert>
                )}
            </Form.Group>
            <Button
                onClick={back ? back : () => navigate(-1)}
                variant="secondary"
            >
                Back
            </Button>{" "}
            <Button type="submit">
                {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                )}
                Save
            </Button>
        </Form>
    );
};

export default ProductForm;
