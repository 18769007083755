import { useEffect, useState } from "react";
import userService from "services/userService";
import productService from "services/productService";
import { User } from "Types";
import CustomCard from "components/CustomCard";
import { Container, Alert, Button } from "react-bootstrap";
import { useParams, Link, useNavigate } from "react-router-dom";
import UserForm from "pages/user/UserForm";
import Restricted from "components/Restricted";
import DataTable, { TableColumn } from "react-data-table-component";
import { Product } from "Types";
import Moment from "moment";

const UserOne = () => {
    const [message, setMessage] = useState("");
    const [data, setData] = useState<User | null>(null);
    const [editMode, setEditMode] = useState(false);
    const params = useParams();
    const [response, setResponse] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (params.id) {
            getUser(params.id);
        } else {
            setEditMode(true);
        }
        // eslint-disable-next-line
    }, [params.id]);

    const getUser = (id: string) =>
        userService.getUser(id).then(
            (response) => {
                if (response.data) {
                    response.data.id = response.data._id;
                    setData(response.data);
                    getUserOwnProducts(response.data);
                }
            },
            (error) => {
                setMessage(error);
            }
        );

    const getUserOwnProducts = (user: User) => {
        const usersProducts = user.products;
        if (usersProducts.length > 0) {
            setLoading(true);
            productService.getProducts(usersProducts).then(
                (response) => {
                    if (response.data) {
                        setResponse(response.data);
                    }
                    setLoading(false);
                },
                (error) => {
                    setMessage(error);
                    setLoading(false);
                }
            );
        }
    };

    const columns: TableColumn<Product>[] = [
        {
            name: "Id",
            selector: (row) => row._id,
            omit: true,
        },
        {
            name: "Serial number",
            selector: (row) => row.uniqueId,
            format: (row) => (
                <Restricted
                    to="products.modify"
                    otherwise={<>{row.uniqueId}</>}
                >
                    <Link to={`/products/${row._id}`}>{row.uniqueId}</Link>
                </Restricted>
            ),
            sortable: true,
            minWidth: "300px",
            allowOverflow: true,
        },
        {
            name: "Created at",
            selector: (row: Product) =>
                Moment(row.createdAt).format("DD/MM/YYYY"),
            sortable: true,
        },
        {
            name: "Updated at",
            selector: (row: Product) =>
                Moment(row.updatedAt).format("DD/MM/YYYY"),
            sortable: true,
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
            },
        },
    };

    const handleBack = () => {
        if (params.id) {
            getUser(params.id);
            setEditMode(false);
        } else {
            navigate(-1);
        }
    };

    return (
        <Container>
            <CustomCard
                header={
                    editMode
                        ? data
                            ? "Modify user"
                            : "Create new user"
                        : "User"
                }
            >
                {message && <Alert variant="danger">{message}</Alert>}
                {!editMode && (
                    <div>
                        <p>
                            <strong>Username:</strong>{" "}
                            {data ? data.username : ""}
                        </p>
                        <p>
                            <strong>Email:</strong> {data ? data.email : ""}
                        </p>
                        <p>
                            <strong>Role:</strong> {data ? data.role : ""}
                        </p>

                        <strong>Linked products:</strong>
                        <DataTable
                            columns={columns}
                            data={response}
                            pagination
                            customStyles={customStyles}
                            striped
                            progressPending={loading}
                        />
                        <p>
                            <Button
                                onClick={() => navigate(-1)}
                                variant="secondary"
                            >
                                Back
                            </Button>{" "}
                            <Restricted to="users.modify">
                                <Button onClick={() => setEditMode(true)}>
                                    Modify user
                                </Button>
                            </Restricted>
                        </p>
                    </div>
                )}
                {editMode && (
                    <Restricted to="users.modify">
                        <UserForm data={data} back={handleBack} />
                    </Restricted>
                )}
            </CustomCard>
        </Container>
    );
};

export default UserOne;
