import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Layout from "components/Layout";
import Login from "pages/Login";
import Profile from "pages/profile/Profile";
import ModPassword from "pages/profile/ModPassword";
import ModEmail from "pages/profile/ModEmail";
import UserList from "pages/user/UserList";
import UserOne from "pages/user/UserOne";
import ProductList from "pages/product/ProductList";
import ProductOne from "pages/product/ProductOne";
import ProductTypeList from "pages/productType/ProductTypeList";
import ProductTypeOne from "pages/productType/ProductTypeOne";
import Registration from "pages/Registration";
import PasswordResetRequest from "pages/passwordReset/PasswordResetRequest";
import PasswordReset from "pages/passwordReset/PasswordReset";
import FileList from "pages/file/FileList";
import NoPage from "pages/NoPage";
import ProtectedRoute from "components/ProtectedRoute";
import useCurrentUser from "hooks/useCurrentUser";

const App = () => {
    const { currentUser, logIn } = useCurrentUser();

    library.add(fas);

    return (
        <Router>
            <Routes>
                <Route element={<Layout currentUser={currentUser} />}>
                    <Route
                        path="/"
                        element={
                            <Login currentUser={currentUser} logIn={logIn} />
                        }
                    />
                    <Route
                        path="/confirmation/:id"
                        element={
                            <Login currentUser={currentUser} logIn={logIn} />
                        }
                    />
                    <Route
                        path="registration/:id"
                        element={<Registration currentUser={currentUser} />}
                    />
                    <Route
                        path="password-reset/:id"
                        element={<PasswordReset currentUser={currentUser} />}
                    />
                    <Route
                        path="password-reset"
                        element={
                            <PasswordResetRequest currentUser={currentUser} />
                        }
                    />

                    <Route
                        path="profile"
                        element={
                            <ProtectedRoute>
                                <Profile />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="modify-password"
                        element={
                            <ProtectedRoute>
                                <ModPassword />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="modify-email"
                        element={
                            <ProtectedRoute>
                                <ModEmail currentUser={currentUser} />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="users"
                        element={
                            <ProtectedRoute>
                                <UserList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="users/:id"
                        element={
                            <ProtectedRoute>
                                <UserOne />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="users/new"
                        element={
                            <ProtectedRoute>
                                <UserOne />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="products"
                        element={
                            <ProtectedRoute>
                                <ProductList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="products/:id"
                        element={
                            <ProtectedRoute>
                                <ProductOne />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="products/new"
                        element={
                            <ProtectedRoute>
                                <ProductOne />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="files/:id"
                        element={
                            <ProtectedRoute>
                                <FileList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="product-types"
                        element={
                            <ProtectedRoute>
                                <ProductTypeList />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="product-types/:id"
                        element={
                            <ProtectedRoute>
                                <ProductTypeOne />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="product-types/new"
                        element={
                            <ProtectedRoute>
                                <ProductTypeOne />
                            </ProtectedRoute>
                        }
                    />
                </Route>
                <Route path="*" element={<NoPage />} />
            </Routes>
        </Router>
    );
};

export default App;
