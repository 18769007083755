import { Modal, Button } from "react-bootstrap";
import { confirmable, createConfirmation } from "react-confirm";

const ConfirmDialog = ({
    okLabel = "OK",
    cancelLabel = "Cancel",
    title = "Confirmation",
    message = "",
    show = false,
    proceed,
    enableEscape = true,
}: {
    okLabel: String;
    cancelLabel: String;
    title: String;
    message: String;
    show: boolean | undefined;
    proceed: (param: any) => void;
    enableEscape: boolean;
}) => {
    return (
        <Modal
            show={show}
            onHide={() => proceed(false)}
            backdrop={enableEscape ? true : "static"}
            keyboard={enableEscape}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => proceed(false)}>
                    {cancelLabel}
                </Button>
                <Button onClick={() => proceed(true)}>{okLabel}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export function confirm(
    message = "",
    proceedLabel = "OK",
    cancelLabel = "Cancel",
    options = {}
) {
    return createConfirmation(confirmable(ConfirmDialog))({
        message,
        proceedLabel,
        cancelLabel,
        ...options,
    });
}
