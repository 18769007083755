import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ProductType } from "Types";
import { MouseEventHandler, useEffect, useState } from "react";
import { Button, Alert, Form } from "react-bootstrap";
import productTypeService from "services/productTypeService";
import { useNavigate } from "react-router-dom";
import { alert } from "components/MessageDialog";

const ProductTypeForm = ({
    data,
    back,
}: {
    data?: ProductType | null;
    back?: MouseEventHandler<HTMLButtonElement>;
}) => {
    const [productType, setProductType] = useState<ProductType>();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const schema = Yup.object({
        uniqueId: Yup.string()
            .test(
                "len",
                "The serial number must be at least 8 characters.",
                (val: any) => val && val.toString().length >= 8
            )
            .matches(
                /^[^\s]*$/,
                "The serial number cannot contain any whitespace characters."
            )
            .required("This field is required!"),
        name: Yup.string().required("This field is required!"),
        description: Yup.string(),
    }).required();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<ProductType>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (data) {
            setProductType(data);
        }
    }, [data]);

    useEffect(() => {
        if (productType) {
            reset({
                id: productType.id,
                uniqueId: productType.uniqueId,
                name: productType.name,
                description: productType.description,
            });
        }
    }, [productType, reset]);

    const onSubmit = (data: ProductType) => {
        setLoading(true);
        setMessage("");

        if (!data.id) {
            productTypeService.createProductType(data).then(
                (response) => {
                    setLoading(false);
                    alert("Successfully created the product type!");
                    navigate("/product-types");
                },
                (error) => {
                    setLoading(false);
                    setMessage(error);
                }
            );
        } else {
            productTypeService.updateProductType(data.id, data).then(
                (response) => {
                    setLoading(false);
                    setMessage("Successfully saved.");
                },
                (error) => {
                    setLoading(false);
                    setMessage(error);
                }
            );
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {message && <Alert variant="info">{message}</Alert>}
            <Form.Group className="mb-3">
                <Form.Label>Type ID</Form.Label>
                <Form.Control type="hidden" {...register("id")} />
                <Form.Control
                    type="text"
                    placeholder="Type ID"
                    {...register("uniqueId")}
                />
                {errors.uniqueId && <Alert>{errors.uniqueId?.message}</Alert>}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Name"
                    {...register("name")}
                />
                {errors.name && <Alert>{errors.name?.message}</Alert>}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Description"
                    {...register("description")}
                />
                {errors.description && (
                    <Alert>{errors.description?.message}</Alert>
                )}
            </Form.Group>
            <Button
                onClick={back ? back : () => navigate(-1)}
                variant="secondary"
            >
                Back
            </Button>{" "}
            <Button type="submit">
                {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                )}
                Save
            </Button>
        </Form>
    );
};

export default ProductTypeForm;
