import axios from "axios";
import eventBus from "common/eventBus";

const API_URL = "/api/";

const api = axios.create({
    baseURL: API_URL,
    timeout: 3000,
});

//validate response
api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log(error);
        let errorMessage = "Error";
        if (error.code === "ECONNABORTED") {
            // timeout
            errorMessage =
                "Sorry, our servers are currently busy. Try again later!";
        } else {
            if (
                error.response &&
                (error.response.status === 401 || error.response.status === 403)
            ) {
                eventBus.dispatch("logout");
                return (window.location.href = "/");
            }
            errorMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
        }
        return Promise.reject(errorMessage);
    }
);

export default api;
