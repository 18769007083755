import React, { useState, useEffect } from "react";

const TagsInput = ({
    selectedTags,
    initTags,
    placeholder = "Press space to add new element",
}: {
    selectedTags: (array: string[]) => void;
    initTags?: string[];
    placeholder?: string;
}) => {
    const [tags, setTags] = useState<string[]>([]);

    useEffect(() => {
        if (initTags) {
            initializeTags(initTags);
        }
        // eslint-disable-next-line
    }, [initTags]);

    const initializeTags = (tags: string[]) => {
        setTags(tags);
        selectedTags(tags);
    };

    const addTags = (event: any) => {
        if (event && event.which === 32 && event.target.value !== "") {
            let newValue = event.target.value.trim();
            setTags([...tags, newValue]);
            selectedTags([...tags, newValue]);
            event.target.value = "";
        }
    };

    const removeTags = (index: Number) => {
        setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
        selectedTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
    };

    return (
        <div className="tags-input">
            <ul id="tags">
                {tags.map((tag, index) => (
                    <li key={index} className="tag">
                        <span className="tag-title">{tag}</span>
                        <span
                            className="tag-close-icon"
                            onClick={() => removeTags(index)}
                        >
                            x
                        </span>
                    </li>
                ))}
            </ul>

            <input
                type="text"
                onKeyUp={(event: React.KeyboardEvent<HTMLElement>) =>
                    addTags(event)
                }
                placeholder={placeholder}
            />
        </div>
    );
};

export default TagsInput;
