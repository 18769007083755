import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Form, Button, Alert, Row, Col } from "react-bootstrap";
import authService from "services/authService";
import { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import userValidation from "services/userValidation";
import CustomCard from "components/CustomCard";
import { User } from "Types";
import eventBus from "common/eventBus";
import { alert } from "components/MessageDialog";

const Registration = ({ currentUser }: { currentUser: User | undefined }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        // if logged in, then we force the log out
        if (currentUser) {
            eventBus.dispatch("logout");
        }
        // we store the product uniqueId in localstorage so we can link it to the user if he log in
        localStorage.setItem("regToken", params.id ? params.id : "");
    }, [currentUser, params.id]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<User>({
        resolver: yupResolver(userValidation.registrationSchema),
    });

    const onSubmit = (data: User) => {
        setLoading(true);
        setMessage("");

        if (params.id) {
            authService
                .register(data, params.id)
                .then((response) => {
                    setLoading(false);
                    alert(
                        "Your registration is almost complete! We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link. If you do not receive a confirmation email, please check your spam folder. Also, please verify that you entered a valid email address in our sign-up form."
                    );
                    navigate("/");
                })
                .catch((error) => {
                    setLoading(false);
                    setMessage(error);
                });
        }
    };

    return (
        <Container style={{ marginTop: "50px" }}>
            <CustomCard>
                <Row className="justify-content-center">
                    <Col xs={6} sm={6} md={4}>
                        <img
                            src="/anemos-logo.svg"
                            alt="Anemos"
                            style={{ height: "155px" }}
                        />
                    </Col>
                </Row>
                <Row>
                    <h1>Registration</h1>
                    <p>
                        Welcome to our update download system! You can complete
                        your registration by filling out the form below.
                    </p>
                    <p>
                        If you already have an account, please log in!{" "}
                        <Link to="/">
                            <Button>Login</Button>
                        </Link>
                    </p>
                </Row>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {message && <Alert variant="info">{message}</Alert>}
                    <Form.Group className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <Form.Control type="hidden" {...register("id")} />
                        <Form.Control
                            type="text"
                            placeholder="Username"
                            {...register("username")}
                        />
                        {errors.username && (
                            <Alert>{errors.username?.message}</Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Email"
                            {...register("email")}
                        />
                        {errors.email && <Alert>{errors.email?.message}</Alert>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Password"
                            {...register("password")}
                        />
                        {errors.password && (
                            <Alert>{errors.password?.message}</Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Confirm password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Confirm password"
                            {...register("passwordConfirmation")}
                        />
                        {errors.passwordConfirmation && (
                            <Alert>
                                {errors.passwordConfirmation?.message}
                            </Alert>
                        )}
                    </Form.Group>
                    <Button type="submit">
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        Sign Up
                    </Button>
                </Form>
            </CustomCard>
        </Container>
    );
};

export default Registration;
