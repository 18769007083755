//import { User } from "Types";
import CustomCard from "components/CustomCard";
import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import authService from "services/authService";

const Profile = () => {
    const currentUser = authService.getCurrentUser();
    return (
        <Container>
            <CustomCard
                header="Profile"
                infoText="This is your profile where you can manage your personal data. You can modify your email and password. We store your data securely and never send spam to your email address."
            >
                <div>
                    <p>
                        <strong>Username:</strong>{" "}
                        {currentUser && currentUser.username}
                    </p>
                    <p>
                        <strong>Email:</strong>{" "}
                        {currentUser && currentUser.email}
                    </p>
                    <p>
                        <strong>Role:</strong> {currentUser && currentUser.role}
                    </p>
                    <Link to="/modify-email">
                        <Button>Modify email</Button>
                    </Link>{" "}
                    <Link to="/modify-password">
                        <Button>Modify password</Button>
                    </Link>
                </div>
            </CustomCard>
        </Container>
    );
};

export default Profile;
