import { useEffect, useState } from "react";
import productTypeService from "services/productTypeService";
import { ProductType } from "Types";
import CustomCard from "components/CustomCard";
import { Container, Alert, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ProductTypeForm from "pages/productType/ProductTypeForm";
import Restricted from "components/Restricted";

const ProductTypeOne = () => {
    const [message, setMessage] = useState("");
    const [data, setData] = useState<ProductType | null>(null);
    const [editMode, setEditMode] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params.id) {
            getProductType(params.id);
        } else {
            setEditMode(true);
        }
    }, [params.id]);

    const getProductType = (id: string) =>
        productTypeService.getProductType(id).then(
            (response) => {
                if (response.data) {
                    response.data.id = response.data._id;
                    setData(response.data);
                }
            },
            (error) => {
                setMessage(error);
            }
        );

    const handleEditMode = () => {
        setEditMode(true);
    };

    const handleBack = () => {
        if (params.id) {
            getProductType(params.id);
            setEditMode(false);
        } else {
            navigate(-1);
        }
    };

    return (
        <Container>
            <CustomCard
                header={
                    editMode
                        ? data
                            ? "Modify type"
                            : "Create new type"
                        : "Product type"
                }
            >
                {message && <Alert variant="danger">{message}</Alert>}
                {!editMode && (
                    <div>
                        <p>
                            <strong>Type ID:</strong>{" "}
                            {data ? data.uniqueId : ""}
                        </p>
                        <p>
                            <strong>Name:</strong> {data ? data.name : ""}
                        </p>
                        <p>
                            <strong>Description:</strong>{" "}
                            {data ? data.description : ""}
                        </p>
                        <p>
                            <Button
                                onClick={() => navigate(-1)}
                                variant="secondary"
                            >
                                Back
                            </Button>{" "}
                            <Restricted to="productTypes.modify">
                                <Button onClick={() => handleEditMode()}>
                                    Modify product type
                                </Button>
                            </Restricted>
                        </p>
                    </div>
                )}
                {editMode && (
                    <Restricted to="productTypes.modify">
                        <ProductTypeForm data={data} back={handleBack} />
                    </Restricted>
                )}
            </CustomCard>
        </Container>
    );
};

export default ProductTypeOne;
