import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { User } from "Types";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import { Button, Alert, Form } from "react-bootstrap";
import userService from "services/userService";
import { useNavigate } from "react-router-dom";
import TagsInput from "components/TagsInput";
import userValidation from "services/userValidation";
import { alert } from "components/MessageDialog";
import Restricted from "components/Restricted";
import PermissionContext from "common/PermissionContext";

const UserForm = ({
    data,
    back,
}: {
    data?: User | null;
    back?: MouseEventHandler<HTMLButtonElement>;
}) => {
    const [user, setUser] = useState<User>();
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { isAllowedTo } = useContext(PermissionContext);

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm<User>({
        resolver: yupResolver(
            user ? userValidation.updateSchema : userValidation.createSchema
        ),
    });

    useEffect(() => {
        if (data) {
            setUser(data);
        }
    }, [data]);

    useEffect(() => {
        if (user) {
            if (
                user.role === "superadmin" &&
                !isAllowedTo("users.modifysuperadmin")
            ) {
                alert("You don't have permission to modify a superadmin!");
                navigate("/users");
            }
            reset({
                id: user.id,
                username: user.username,
                email: user.email,
                role: user.role,
                products: user.products,
            });
        }
    }, [user, reset, isAllowedTo, navigate]);

    const onSubmit = (data: User) => {
        setLoading(true);
        setMessage("");

        if (!data.id) {
            userService.createUser(data).then(
                (response) => {
                    setLoading(false);
                    alert("Successfully created the user!");
                    navigate("/users");
                    //navigate("/users/" + response.data._id);
                },
                (error) => {
                    setLoading(false);
                    setMessage(error);
                }
            );
        } else {
            userService.updateUser(data.id, data).then(
                (response) => {
                    setLoading(false);
                    setMessage("Successfully saved.");
                },
                (error) => {
                    setLoading(false);
                    setMessage(error);
                }
            );
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {message && <Alert variant="info">{message}</Alert>}
            <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <Form.Control type="hidden" {...register("id")} />
                <Form.Control
                    type="text"
                    placeholder="Username"
                    {...register("username")}
                />
                {errors.username && <Alert>{errors.username?.message}</Alert>}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Email"
                    {...register("email")}
                />
                {errors.email && <Alert>{errors.email?.message}</Alert>}
            </Form.Group>
            {!user && (
                <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Password"
                        {...register("password")}
                    />
                    {errors.password && (
                        <Alert>{errors.password?.message}</Alert>
                    )}
                </Form.Group>
            )}
            <Form.Group className="mb-3">
                <Form.Label>Role</Form.Label>
                <Form.Select
                    aria-label="Default select example"
                    {...register("role")}
                >
                    <option value="user">User</option>
                    <option value="admin">Admin</option>
                    <Restricted to="users.modifysuperadmin">
                        <option value="superadmin">Superadmin</option>
                    </Restricted>
                </Form.Select>
                {errors.role && <Alert>{errors.role?.message}</Alert>}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Linked products</Form.Label>
                <TagsInput
                    initTags={user?.products}
                    selectedTags={(productsList) =>
                        setValue("products", productsList)
                    }
                    placeholder="Press space to add new product serial number"
                />
            </Form.Group>
            <Button
                onClick={back ? back : () => navigate(-1)}
                variant="secondary"
            >
                Back
            </Button>{" "}
            <Button type="submit">
                {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                )}
                Save
            </Button>
        </Form>
    );
};

export default UserForm;
