import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Form, Button, Alert, Row, Col } from "react-bootstrap";
import passwordResetService from "services/passwordResetService";
import userValidation from "services/userValidation";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomCard from "components/CustomCard";
import { User, PasswordResetForm } from "Types";
import eventBus from "common/eventBus";
import { alert } from "components/MessageDialog";
import * as yup from "yup";

const PasswordReset = ({ currentUser }: { currentUser: User | undefined }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [editMode, setEditMode] = useState(false);
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        if (params.id) {
            passwordResetService.checkPasswordReset(params.id).then((data) => {
                setEditMode(true);
            });
        }
    }, [params.id]);

    useEffect(() => {
        // if logged in, then we force the log out
        if (currentUser) {
            eventBus.dispatch("logout");
        }
        // we store the product uniqueId in localstorage so we can link it to the user if he log in
        localStorage.setItem("regToken", params.id ? params.id : "");
    }, [currentUser, params.id]);

    const validationSchema = yup
        .object({
            password: userValidation.passwordRule,
            passwordConfirmation: userValidation.passwordConfirmationRule,
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<PasswordResetForm>({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (data: PasswordResetForm) => {
        setLoading(true);
        setMessage("");

        if (params.id) {
            passwordResetService
                .updatePassword(params.id, data.password)
                .then((response) => {
                    setLoading(false);
                    alert("Your password has been successfully changed!");
                    navigate("/");
                })
                .catch((error) => {
                    setLoading(false);
                    setMessage(error);
                });
        }
    };

    return (
        <Container style={{ marginTop: "50px" }}>
            <CustomCard>
                <Row className="justify-content-center">
                    <Col xs={6} sm={6} md={4}>
                        <img
                            src="/anemos-logo.svg"
                            alt="Anemos"
                            style={{ height: "155px" }}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={6}>
                        <h1>Reset password</h1>
                        {!editMode && (
                            <div style={{ paddingBottom: "50px" }}>
                                <Alert variant="info">
                                    The password reset link has expired!
                                </Alert>
                                <p>
                                    For security, reset password links are valid
                                    for 3 hours.
                                </p>
                                <Button
                                    onClick={() => navigate("/password-reset")}
                                >
                                    Get a new reset password link
                                </Button>{" "}
                                <Button
                                    onClick={() => navigate("/")}
                                    variant="secondary"
                                >
                                    Go to Login page
                                </Button>
                            </div>
                        )}
                        {editMode && (
                            <p>
                                Please fill the form below to change your
                                password.
                            </p>
                        )}
                    </Col>
                </Row>
                {editMode && (
                    <Row className="justify-content-center">
                        <Col lg={6} style={{ paddingBottom: "50px" }}>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                {message && (
                                    <Alert variant="info">{message}</Alert>
                                )}
                                <Form.Group className="mb-3">
                                    <Form.Label>New password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="New password"
                                        {...register("password")}
                                    />
                                    {errors.password && (
                                        <Alert>
                                            {errors.password?.message}
                                        </Alert>
                                    )}
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Confirm new password
                                    </Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirm password"
                                        {...register("passwordConfirmation")}
                                    />
                                    {errors.passwordConfirmation && (
                                        <Alert>
                                            {
                                                errors.passwordConfirmation
                                                    ?.message
                                            }
                                        </Alert>
                                    )}
                                </Form.Group>
                                <Button type="submit">
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    Save new password
                                </Button>{" "}
                                <Button
                                    onClick={() => navigate("/")}
                                    variant="secondary"
                                >
                                    Back to the Login page
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                )}
            </CustomCard>
        </Container>
    );
};

export default PasswordReset;
