import { useEffect, useState, useContext } from "react";
import productService from "services/productService";
import CustomCard from "components/CustomCard";
import { Button, Container, Alert } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import { Product } from "Types";
import Moment from "moment";
import Restricted from "components/Restricted";
import PermissionContext from "common/PermissionContext";
import { Link } from "react-router-dom";
import { confirm } from "components/ConfirmDialog";

const ProductList = () => {
    const [message, setMessage] = useState("");
    const [response, setResponse] = useState([]);
    const [loading, setLoading] = useState(false);

    const { isAllowedTo } = useContext(PermissionContext);

    useEffect(() => {
        getProductsList();
    }, []);

    const handleDelete = async (id: string) => {
        if (
            await confirm(
                "Are you sure you wish to delete this item? It's cannot be undone."
            )
        ) {
            productService.deleteProduct(id).then(
                (response) => {
                    setMessage("Successfully deleted.");
                    getProductsList();
                },
                (error) => {
                    setMessage(error);
                }
            );
        }
    };

    const getProductsList = () => {
        setLoading(true);
        productService.getProducts().then(
            (response) => {
                if (response.data) {
                    setResponse(response.data);
                }
                setLoading(false);
            },
            (error) => {
                setMessage(error);
                setLoading(false);
            }
        );
    };

    const columns: TableColumn<Product>[] = [
        {
            name: "Id",
            selector: (row) => row._id,
            omit: true,
        },
        {
            name: "Serial number",
            selector: (row) => row.uniqueId,
            format: (row) => (
                <Link to={`/products/${row._id}`}>{row.uniqueId}</Link>
            ),
            sortable: true,
            minWidth: "300px",
            allowOverflow: true,
        },
        {
            name: "Type",
            selector: (row) =>
                row.productType.name + " (" + row.productType.uniqueId + ")",
            sortable: true,
            minWidth: "300px",
            allowOverflow: true,
        },
        ...(isAllowedTo("products.modify")
            ? [
                  {
                      name: "Created at",
                      selector: (row: Product) =>
                          Moment(row.createdAt).format("DD/MM/YYYY"),
                      sortable: true,
                      minWidth: "112px",
                  },
                  {
                      name: "Updated at",
                      selector: (row: Product) =>
                          Moment(row.updatedAt).format("DD/MM/YYYY"),
                      sortable: true,
                      minWidth: "112px",
                  },
              ]
            : []),
        ...(isAllowedTo("products.delete")
            ? [
                  {
                      name: "Action",
                      cell: (row: Product) => (
                          <Button
                              variant="outline-danger"
                              onClick={() => {
                                  handleDelete(row._id);
                              }}
                          >
                              Delete
                          </Button>
                      ),
                      minWidth: "112px",
                  },
              ]
            : []),
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
            },
        },
    };

    return (
        <Container>
            <CustomCard
                header="Products"
                infoText="On this page, you can view a list of products. By clicking on a serial number, you can view details about that product and download the latest updates."
            >
                {message && <Alert variant="info">{message}</Alert>}
                <Restricted to="products.modify">
                    <Link to="/products/new">
                        <Button>Add product</Button>
                    </Link>
                </Restricted>
                <DataTable
                    columns={columns}
                    data={response}
                    pagination
                    customStyles={customStyles}
                    striped
                    progressPending={loading}
                />
            </CustomCard>
        </Container>
    );
};

export default ProductList;
