import api from "services/api";
import { User, LoggedUserMod } from "Types";
import authHeader from "services/authHeader";

const API_URL = "users/";

const getUsers = () => {
    return api.get(API_URL, { headers: authHeader() });
};

const getUser = (id: string) => {
    return api.get(API_URL + id, { headers: authHeader() });
};

const createUser = (user: User) => {
    return api.post(API_URL, user, { headers: authHeader() });
};

const updateUser = (id: string, user: User) => {
    return api.put(API_URL + id, user, { headers: authHeader() });
};

const updateLoggedUser = (user: LoggedUserMod) => {
    return api
        .put(API_URL + "me", user, { headers: authHeader() })
        .then((response) => {
            if (user.email) {
                // if email modification, send confirmation email
                return api.post(
                    "auth/confirmation",
                    {
                        email: user.email,
                        link:
                            window.location.protocol +
                            "//" +
                            window.location.hostname +
                            (window.location.port
                                ? ":" + window.location.port
                                : "") +
                            "/confirmation/",
                        userId: response.data._id,
                    },
                    { timeout: 10000 }
                );
            } else {
                return Promise.resolve(response);
            }
        })
        .catch((error) => {
            return Promise.reject(error);
        });
};

const getLoggedUser = () => {
    return api.get(API_URL + "me", { headers: authHeader() });
};

const deleteUser = (id: string) => {
    return api.delete(API_URL + id, { headers: authHeader() });
};

const userService = {
    getUsers,
    getUser,
    createUser,
    updateUser,
    updateLoggedUser,
    getLoggedUser,
    deleteUser,
};

export default userService;
