import api from "services/api";

const API_URL = "password-reset/";

const createPasswordReset = (email: string) => {
    return api.post(
        API_URL,
        {
            email,
            link:
                window.location.protocol +
                "//" +
                window.location.hostname +
                (window.location.port ? ":" + window.location.port : "") +
                "/password-reset/",
        },
        { timeout: 10000 }
    );
};

const checkPasswordReset = (code: string) => {
    return api.get(API_URL + code);
};

const updatePassword = (code: string, password: string) => {
    return api.post(API_URL + code, { newPassword: password });
};

const passwordResetService = {
    createPasswordReset,
    checkPasswordReset,
    updatePassword,
};

export default passwordResetService;
