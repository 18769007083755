import { useEffect, useState } from "react";
import fileService from "services/fileService";
import CustomCard from "components/CustomCard";
import { Container, Alert, Button } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import { File } from "Types";
import Moment from "moment";
//import PermissionContext from "common/PermissionContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { alert, closeAlert } from "components/MessageDialog";

const FileList = () => {
    const [message, setMessage] = useState("");
    const [response, setResponse] = useState([]);
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    //const { isAllowedTo } = useContext(PermissionContext);

    useEffect(() => {
        if (params.id) {
            getFilesList(params.id);
        } else {
            // error
            setMessage("Missing product id");
        }
    }, [params.id]);

    const getFilesList = (id: string) => {
        setLoading(true);
        fileService.getFiles(id).then(
            (response) => {
                if (response.data) {
                    setResponse(response.data);
                }
                setLoading(false);
            },
            (error) => {
                setMessage(error);
                setLoading(false);
            }
        );
    };

    const download = async (id: string, name: string) => {
        try {
            alert("Please wait! Downloading...");
            const res = await fileService.download(id);
            const downloadUrl = URL.createObjectURL(res.data);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", name);
            document.body.appendChild(link);
            link.click();
            link.remove();
            URL.revokeObjectURL(downloadUrl);
            closeAlert();
        } catch (e) {
            alert(
                "Sorry, an error occurred while downloading. Please try again later!"
            );
        }
    };

    const columns: TableColumn<File>[] = [
        {
            name: "Id",
            selector: (row) => row._id,
            omit: true,
        },
        {
            name: "Filename",
            selector: (row) => row.name,
            format: (row) => (
                <Link to="#" onClick={() => download(row._id, row.name)}>
                    {row.name}
                </Link>
            ),
            sortable: true,
            minWidth: "350px",
        },
        {
            name: "Description",
            selector: (row) => row.description,
            sortable: true,
            minWidth: "300px",
        },
        {
            name: "Date of release",
            selector: (row) => Moment(row.createdAt).format("DD/MM/YYYY"),
            sortable: true,
            minWidth: "125px",
            allowOverflow: true,
        },
        {
            name: "Action",
            cell: (row: File) => (
                <span className="row-actions">
                    <Link
                        to="#"
                        onClick={(e) => {
                            //e.preventDefault();
                            download(row._id, row.name);
                        }}
                    >
                        <Button>Download</Button>
                    </Link>
                    <Link
                        to="#"
                        onClick={(e) => {
                            fileService.cancelTokenSource.cancel();
                        }}
                    >
                        <Button>Cancel</Button>
                    </Link>
                </span>
            ),
            minWidth: "230px",
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
            },
        },
    };

    return (
        <Container>
            <CustomCard
                header="Updates"
                infoText="On this page, you can view a list of update files for the product. By clicking on the filename you can download it."
            >
                {message && <Alert variant="info">{message}</Alert>}
                <DataTable
                    columns={columns}
                    data={response}
                    pagination
                    customStyles={customStyles}
                    striped
                    progressPending={loading}
                />
                <Button onClick={() => navigate(-1)} variant="secondary">
                    Back
                </Button>
            </CustomCard>
        </Container>
    );
};

export default FileList;
