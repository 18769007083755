import React from "react";
import { Card } from "react-bootstrap";
import InfoPopover from "./InfoPopover";

const CustomCard = ({
    children,
    header,
    infoText,
}: {
    children: React.ReactNode;
    header?: string;
    infoText?: string;
}) => {
    return (
        <Card border="light">
            <Card.Body>
                <Card.Title as="h2">
                    {header}{" "}
                    {infoText && (
                        <InfoPopover header="About this page" text={infoText} />
                    )}
                </Card.Title>
                {children}
            </Card.Body>
        </Card>
    );
};

export default CustomCard;
