import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";

const NoPage = () => {
    return (
        <Container>
            <Alert
                variant="secondary"
                style={{
                    width: "100%",
                    textAlign: "center",
                }}
            >
                <Alert.Heading>404 Page not found.</Alert.Heading>
                <p>Sorry! The page you're looking for can't be found.</p>
                <Link to="/">Go back to the home page</Link>
            </Alert>
        </Container>
    );
};

export default NoPage;
