import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Container, Form, Button, Alert, Row, Col } from "react-bootstrap";
import passwordResetService from "services/passwordResetService";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomCard from "components/CustomCard";
import { User } from "Types";
import eventBus from "common/eventBus";
import { alert } from "components/MessageDialog";
import * as yup from "yup";

const PasswordResetRequest = ({
    currentUser,
}: {
    currentUser: User | undefined;
}) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        // if logged in, then we force the log out
        if (currentUser) {
            eventBus.dispatch("logout");
        }
    }, [currentUser]);

    const validationSchema = yup
        .object({
            email: yup
                .string()
                .email("This is not a valid email.")
                .required("This field is required!"),
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<{ email: string }>({
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = (data: { email: string }) => {
        setLoading(true);
        setMessage("");

        passwordResetService.createPasswordReset(data.email).then(
            (response) => {
                setLoading(false);
                alert(
                    "The e-mail containing the link to reset the password has been successfully sent!"
                );
                navigate("/");
            },
            (error) => {
                setLoading(false);
                setMessage(error);
            }
        );
    };

    return (
        <Container style={{ marginTop: "50px" }}>
            <CustomCard>
                <Row className="justify-content-center">
                    <Col xs={6} sm={6} md={4}>
                        <img
                            src="/anemos-logo.svg"
                            alt="Anemos"
                            style={{ height: "155px" }}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={6}>
                        <h1>Reset password</h1>
                        <p>
                            Just enter the email address you registered with and
                            we’ll send you a link to reset your password.
                        </p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={6} style={{ paddingBottom: "50px" }}>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            {message && <Alert variant="info">{message}</Alert>}
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Email"
                                    {...register("email")}
                                />
                                {errors.email && (
                                    <Alert>{errors.email?.message}</Alert>
                                )}
                            </Form.Group>
                            <Button type="submit">
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                Send password reset link
                            </Button>{" "}
                            <Button
                                onClick={() => navigate("/")}
                                variant="secondary"
                            >
                                Back to the Login page
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </CustomCard>
        </Container>
    );
};

export default PasswordResetRequest;
