import eventBus from "common/eventBus";
import { useEffect, useState } from "react";
import authService from "services/authService";
import { User } from "Types";

const useCurrentUser = () => {
    const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);

    useEffect(() => {
        refreshCurrentUser();

        eventBus.on("refreshCurrentUser", () => {
            refreshCurrentUser();
        });

        eventBus.on("logout", () => {
            logOut();
        });

        return () => {
            eventBus.remove("logout", () => {
                logOut();
            });
            eventBus.remove("refreshCurrentUser", () => {
                refreshCurrentUser();
            });
        };
    }, []);

    const refreshCurrentUser = () => {
        const user = authService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
        }
    };

    const logOut = () => {
        authService.logout();
        setCurrentUser(undefined);
    };

    const logIn = (user: any) => {
        setCurrentUser(user);
        localStorage.removeItem("regToken");
    };

    return {
        currentUser,
        refreshCurrentUser,
        logIn,
        logOut,
    };
};

export default useCurrentUser;
