import api from "services/api";
import authHeader from "services/authHeader";
import axios from "axios";

const API_URL = "files/";
const cancelTokenSource = axios.CancelToken.source();

const getFiles = (productUniqueId: String) => {
    return api.get(API_URL, {
        headers: authHeader(),
        params: { productUniqueId: productUniqueId },
    });
};

const download = (id: String) => {
    return api.get(API_URL + "download/" + id, {
        responseType: "blob",
        headers: authHeader(),
        timeout: 300000, //file download timeout (almost unlimited)
        cancelToken: cancelTokenSource.token,
    });
};

const fileService = {
    getFiles,
    download,
    cancelTokenSource,
};

export default fileService;
