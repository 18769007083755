import { useEffect, useState } from "react";
import productService from "services/productService";
import { Product } from "Types";
import CustomCard from "components/CustomCard";
import { Container, Alert, Button } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import ProductForm from "pages/product/ProductForm";
import Restricted from "components/Restricted";

const ProductOne = () => {
    const [message, setMessage] = useState("");
    const [data, setData] = useState<Product | null>(null);
    const [editMode, setEditMode] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params.id) {
            getProduct(params.id);
        } else {
            setEditMode(true);
        }
    }, [params.id]);

    const getProduct = (id: string) =>
        productService.getProduct(id).then(
            (response) => {
                if (response.data) {
                    response.data.id = response.data._id;
                    setData(response.data);
                }
            },
            (error) => {
                setMessage(error);
            }
        );

    const handleEditMode = () => {
        setEditMode(true);
    };

    const handleBack = () => {
        if (params.id) {
            getProduct(params.id);
            setEditMode(false);
        } else {
            navigate(-1);
        }
    };

    return (
        <Container>
            <CustomCard
                header={
                    editMode
                        ? data
                            ? "Modify product"
                            : "Create new product"
                        : "Product"
                }
            >
                {message && <Alert variant="danger">{message}</Alert>}
                {!editMode && (
                    <div>
                        <p>
                            <strong>Serial number:</strong>{" "}
                            {data ? data.uniqueId : ""}
                        </p>
                        <p>
                            <strong>Type:</strong>{" "}
                            {data && data.productType
                                ? data.productType.name
                                : ""}{" "}
                            ({data ? data.productType?.uniqueId : ""})
                        </p>
                        <p>
                            <strong>Description:</strong>{" "}
                            {data && data.productType
                                ? data.productType.description
                                : ""}
                        </p>
                        <Restricted to="products.modify">
                            <p>
                                <strong>Registration link:</strong>{" "}
                                {data
                                    ? window.location.protocol +
                                      "//" +
                                      window.location.hostname +
                                      (window.location.port
                                          ? ":" + window.location.port
                                          : "") +
                                      "/registration/" +
                                      data.uniqueId
                                    : ""}
                            </p>
                        </Restricted>
                        <p>
                            <Button
                                onClick={() => navigate(-1)}
                                variant="secondary"
                            >
                                Back
                            </Button>{" "}
                            <Restricted to="products.modify">
                                <Button onClick={() => handleEditMode()}>
                                    Modify product
                                </Button>
                            </Restricted>{" "}
                            <Link to={`/files/${data ? data.uniqueId : ""}`}>
                                <Button>Download updates</Button>
                            </Link>
                        </p>
                    </div>
                )}
                {editMode && (
                    <Restricted to="products.modify">
                        <ProductForm data={data} back={handleBack} />
                    </Restricted>
                )}
            </CustomCard>
        </Container>
    );
};

export default ProductOne;
