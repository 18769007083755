import api from "services/api";
import { ProductType } from "Types";
import authHeader from "services/authHeader";

const API_URL = "product-types/";

const getProductTypes = (uniqueIds: String[] = []) => {
    const params = uniqueIds ? { uniqueIds: uniqueIds } : {};
    return api.get(API_URL, { headers: authHeader(), params: params });
};

const getProductType = (id: string) => {
    return api.get(API_URL + id, { headers: authHeader() });
};

const createProductType = (productType: ProductType) => {
    return api.post(API_URL, productType, { headers: authHeader() });
};

const updateProductType = (id: string, productType: ProductType) => {
    return api.put(API_URL + id, productType, { headers: authHeader() });
};

const deleteProductType = (id: string) => {
    return api.delete(API_URL + id, { headers: authHeader() });
};

const productTypeService = {
    getProductTypes,
    getProductType,
    createProductType,
    updateProductType,
    deleteProductType,
};

export default productTypeService;
