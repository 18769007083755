import api from "services/api";
import { Product } from "Types";
import authHeader from "services/authHeader";

const API_URL = "products/";

const getProducts = (uniqueIds: String[] = []) => {
    const params = uniqueIds ? { uniqueIds: uniqueIds } : {};
    return api.get(API_URL, { headers: authHeader(), params: params });
};

const getProduct = (id: string) => {
    return api.get(API_URL + id, { headers: authHeader() });
};

const createProduct = (product: Product) => {
    return api.post(API_URL, product, { headers: authHeader() });
};

const updateProduct = (id: string, product: Product) => {
    return api.put(API_URL + id, product, { headers: authHeader() });
};

const deleteProduct = (id: string) => {
    return api.delete(API_URL + id, { headers: authHeader() });
};

const productService = {
    getProducts,
    getProduct,
    createProduct,
    updateProduct,
    deleteProduct,
};

export default productService;
